// import { FaFileExcel, FaFileImport } from "react-icons/fa"; // Import the desired icons
// import { GrCompare } from "react-icons/gr";
// import { FaRegEdit } from "react-icons/fa";
// import Home from "./components/pages/Home/Home";
// import EditProduct from "./components/pages/EditProduct";
// import ComparePrices from "./components/pages/ComparePrices/ComparePrices";
// import ImportLAD from "./components/pages/Ecommerce/importLAD";

// interface Routes {
//   [key: string]: Route;
// }
// interface Route {
//   path?: string;
//   icon?: JSX.Element;
//   component?: React.FC;
//   subRoutes?: Routes;
// }
// let rolePriceComparator = localStorage.getItem("RightPriceComparator") || "";

// export const routesComponent: Routes = {
//   "Ajout d'articles": {
//     path: "/home",
//     icon: <FaFileExcel />,
//     component: <Home />,
//   },
//   "Modification d'article ": {
//     path: "/editProduct",
//     icon: <FaRegEdit />,
//     component: <EditProduct />,
//   },

//   Plateforme: {
//     subRoutes: {
//       "Comparateur de prix": {
//         path: "/comparingPrices",
//         icon: <GrCompare />,
//         component: ComparePrices,
//       },
//       "Ajout d'articles": {
//         path: "/home",
//         icon: <FaFileExcel />,
//         component: Home,
//       },
//       "Modification d'article ": {
//         path: "/editProduct",
//         icon: <FaRegEdit />,
//         component: EditProduct,
//       },
//     },
//   },
//   "E-commerce": {
//     subRoutes: {
//       "Import LAD": {
//         path: "/importLAD",
//         icon: <FaFileImport />,
//         component: ImportLAD,
//       },
//     },
//   },
// };
import { FaFileExcel, FaFileImport, FaHome } from "react-icons/fa";
import { GrCompare } from "react-icons/gr";
import { FaRegEdit } from "react-icons/fa";
import Home from "./components/pages/Home/Home";
import EditProduct from "./components/pages/EditProduct";
import ComparePrices from "./components/pages/ComparePrices/ComparePrices";
import ImportLAD from "./components/pages/Ecommerce/importLAD";
import Accueil from "./components/pages/Accueil/Accueil";

interface Routes {
  [key: string]: Route;
}
interface Route {
  path?: string;
  icon?: JSX.Element;
  subRouteDescription?: string;
  component?: React.FC;
  subRoutes?: Routes;
}

// Retrieve user rights from localStorage

const rolePriceComparator = localStorage.getItem("rights") || "";
//("PlateformComparePricesEcommerceImportLADAddArticles<EditProduct");
console.log("test", localStorage.getItem("rights"));
// Function to generate routes based on user rights
export const generateRoutes = (role: string): Routes => {
  const routes: Routes = {};
  routes["Accueil"] = {
    path: "/Accueil",
    icon: <FaHome />,
    component: Accueil,
  };
  const hasPlateformAccess = role.includes("Plateform");
  const hasEcommerceAccess = role.includes("Ecommerce");
  const hasComparePricesAccess = role.includes("ComparePrices");
  const hasAddArticlesAccess = role.includes("AddArticles");
  const hasEditProductAccess = role.includes("EditProduct");
  const hasImportLADAccess = role.includes("ImportLAD");

  if (hasPlateformAccess) {
    const plateformeSubRoutes: Routes = {};

    if (hasComparePricesAccess) {
      plateformeSubRoutes["Comparateur de prix"] = {
        path: "/Plateform/ComparePrices",
        icon: <GrCompare />,
        subRouteDescription: "Comparer les prix d'un adhérent pour connaitre quel produit est plus rentable côté plateforme ou client",
        component: ComparePrices,
      };
    }

    if (hasAddArticlesAccess) {
      plateformeSubRoutes["Ajout d'articles"] = {
        path: "/Plateform/AddArticles",
        icon: <FaFileExcel />,
        subRouteDescription: "Permet d'ajouter plusieurs articles grâce à un fichier excel.",
        component: Home,
      };
    }

    if (hasEditProductAccess) {
      plateformeSubRoutes["Modification d'article"] = {
        path: "/Plateform/EditProduct",
        icon: <FaRegEdit />,
        subRouteDescription: "Permet de modifier un article d'une société",
        component: EditProduct,
      };
    }

    if (Object.keys(plateformeSubRoutes).length > 0) {
      routes["Plateforme"] = {
        subRoutes: plateformeSubRoutes,
      };
    }
  }

  if (hasEcommerceAccess) {
    const ecommerceSubRoutes: Routes = {};

    if (hasImportLADAccess) {
      ecommerceSubRoutes["Import LAD"] = {
        path: "/Ecommerce/ImportLAD",
        icon: <FaFileImport />,
        subRouteDescription: "Importation en base de donnée grâce à un fichier Excel",
        component: ImportLAD,
      };
    }

    if (Object.keys(ecommerceSubRoutes).length > 0) {
      routes["E-commerce"] = {
        subRoutes: ecommerceSubRoutes,
      };
    }
  }

  return routes;
};

// Generate routes based on the retrieved rights
export const routesComponent: Routes = generateRoutes(rolePriceComparator);
