import { Typography, Pagination } from "@mui/material/";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridFooterContainer,
} from "@mui/x-data-grid";
import React from "react";

interface DefaultGridPaginationProps {
  itemsCount: number;
  itemsTitle: string;
  itemsperpage: number;
  totalPages: number;
  currentPage: number;
}

// augment the props for `pagination` slot
declare module "@mui/x-data-grid" {
  interface PaginationPropsOverrides {
    itemsCount: number;
    itemsTitle: string;
    itemsperpage: number;
    totalPages: number;
    currentPage: number;
  }
}

export const PaginationDatagrid = ({
  itemsCount,
  itemsTitle,
  itemsperpage,
  totalPages,
  currentPage,
}: DefaultGridPaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  var pageCount;
  pageCount = itemsCount / itemsperpage;
  console.log();
  console.log("page count", itemsperpage, page);
  return (
    <GridFooterContainer sx={{ border: "none", width: "100%", padding: "8px" }}>
      <Typography
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {itemsCount} {itemsTitle}
      </Typography>
      <Pagination
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        color="standard"
        count={totalPages}
        page={currentPage}
        onChange={(_event, value) => {
          apiRef.current.setPage(value - 1);
        }}
        showFirstButton
        showLastButton
      />
    </GridFooterContainer>
  );
};
