// drawerReducer.ts
import { Reducer } from 'redux';
import { SET_DRAWER_CLOSED, SET_DRAWER_OPEN, TOGGLE_DRAWER } from './drawerActions';

export interface DrawerState {
    isOpen: boolean;
}

const initialDrawerState: DrawerState = {
    isOpen: false,
};

const drawerReducer: Reducer<DrawerState> = (state = initialDrawerState, action) => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        case SET_DRAWER_OPEN:
            return {
                ...state,
                isOpen: true,
            };
        case SET_DRAWER_CLOSED:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
};

export default drawerReducer;
