// msalConfig.ts
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_AUTH_CLIENT_ID!,
    authority: process.env.REACT_APP_MS_AUTH_AUTHORITY!,
    redirectUri: process.env.REACT_APP_MS_AUTH_REDIRECT_URI!,
  },
  cache: {
    cacheLocation: "localStorage", // Change this to 'localStorage'
    storeAuthStateInCookie: false, // Set to true if issues with IE
  }
};

export default msalConfig;