// subDrawerReducer.js
import { SET_SUB_DRAWER_OPEN } from "./subDrawerActions";

export interface SubDrawerState {
    isOpen: string;
}

const initialState = {
    isOpen: null,
};

const subDrawerReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case SET_SUB_DRAWER_OPEN:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};

export default subDrawerReducer;
