// rootReducer.ts
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import drawerReducer, { DrawerState } from './drawerReducer';
import subDrawerReducer, { SubDrawerState } from './subDrawerReducer';

export interface RootState {
  drawer: DrawerState;
  subDrawer: SubDrawerState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  subDrawer: subDrawerReducer,
});

export default rootReducer;
