// drawerActions.ts
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
export const SET_DRAWER_CLOSED = 'SET_DRAWER_CLOSED';

export const toggleDrawer = () => ({
    type: TOGGLE_DRAWER,
});

export const setDrawerOpen = () => ({
    type: SET_DRAWER_OPEN,
});

export const setDrawerClosed = () => ({
    type: SET_DRAWER_CLOSED,
});
