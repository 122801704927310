import { m } from "framer-motion";
import { useLocation } from "react-router-dom";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
// hooks
// config
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
