let fakeData

export default fakeData =
{
    "fakePagination": {

        totalRecords: 2446,
        totalPages: 123,
        currentPage: 1,
        nextPage: 2,
        previousPage: 1,

    },
    "fakeStat": {
        whereClientPriceIsBetter: 126,
        whereClientPriceIsWrong: 890,
        itemsBoughtOnPlateform: 1193,
    },
    'fakerole': {
        role: "admin",
        databases: [
            {
                "databaseName": "AGRINORD",
                "clientName": "AGRINORD"
            },
            {
                "databaseName": "AJIMAT",
                "clientName": "AJIMATERIAUX"
            },
            {
                "databaseName": "AOC",
                "clientName": "AOC Matéri"
            },
            {
                "databaseName": "APMAT",
                "clientName": "AP Matériaux"
            },
            {
                "databaseName": "AUTRET",
                "clientName": ""
            },
            {
                "databaseName": "AVAGRICOLE",
                "clientName": "SAS AVENIR AGRICOLE"
            },
            {
                "databaseName": "BATIGRES",
                "clientName": "BATIGRES"
            },
            {
                "databaseName": "BAUDE",
                "clientName": "Baude Billet"
            },
            {
                "databaseName": "BOISMAT",
                "clientName": "BOISMAT"
            },
            {
                "databaseName": "BURIE",
                "clientName": "Burie Matériaux"
            },
            {
                "databaseName": "CAMIB",
                "clientName": "Camib"
            },
            {
                "databaseName": "CGMAT",
                "clientName": "CGMAT"
            },
            {
                "databaseName": "COLLIN",
                "clientName": "Ets Collin"
            },
            {
                "databaseName": "COMPTOIRMT",
                "clientName": "Comptoir Des Matériaux"
            },
            {
                "databaseName": "CSP",
                "clientName": "CSP"
            },
            {
                "databaseName": "DBMAT",
                "clientName": "DB Matériaux"
            },
            {
                "databaseName": "DELAGE",
                "clientName": "Delage SAS"
            },
            {
                "databaseName": "FAGES",
                "clientName": "Fages Maté"
            },
            {
                "databaseName": "FELIX",
                "clientName": "Felix"
            },
            {
                "databaseName": "GESTAS",
                "clientName": "Gestas"
            },
            {
                "databaseName": "GILLES",
                "clientName": "Gilles Matériaux"
            },
            {
                "databaseName": "GIRARD",
                "clientName": "GIRARD Matériaux"
            },
            {
                "databaseName": "GRANDMAT",
                "clientName": "GRAND MATERIAUX"
            },
            {
                "databaseName": "GUILLEMOT",
                "clientName": "Guillemot"
            },
            {
                "databaseName": "GUISLAIN",
                "clientName": "Ets Guislain"
            },
            {
                "databaseName": "JMATERIAUX",
                "clientName": "JMatériaux"
            },
            {
                "databaseName": "LACROIX",
                "clientName": "Lacroix"
            },
            {
                "databaseName": "LANNOY",
                "clientName": "LANNOY Matériaux"
            },
            {
                "databaseName": "LAYROL",
                "clientName": "Layrol"
            },
            {
                "databaseName": "LEFRANCOIS",
                "clientName": "Ets LEFRANCOIS"
            },
            {
                "databaseName": "LIMOUSIN",
                "clientName": "Limousin"
            },
            {
                "databaseName": "MARRE",
                "clientName": "Marre Matériaux"
            },
            {
                "databaseName": "MATBOIS",
                "clientName": "Mat Bois"
            },
            {
                "databaseName": "MATEMUSE",
                "clientName": "Matemuse"
            },
            {
                "databaseName": "MOREL",
                "clientName": "Scierie Mo"
            },
            {
                "databaseName": "MOULET",
                "clientName": "MOULET MATERIAUX NEGOCE"
            },
            {
                "databaseName": "MTETAMPOIS",
                "clientName": "Matériaux"
            },
            {
                "databaseName": "MTSERVICES",
                "clientName": "Matériaux"
            },
            {
                "databaseName": "MULTIMAT",
                "clientName": "SARL MUTI MATERIAUX"
            },
            {
                "databaseName": "NOUVOBOIS",
                "clientName": "Nouvobois"
            },
            {
                "databaseName": "PISANO",
                "clientName": "Pisano"
            },
            {
                "databaseName": "PMD",
                "clientName": "PMD"
            },
            {
                "databaseName": "PRELEVEQUE",
                "clientName": "SCI du PRE"
            },
            {
                "databaseName": "PROVENCALE",
                "clientName": "GIRARD"
            },
            {
                "databaseName": "SANITCONFO",
                "clientName": "SANIT CONFORT"
            },
            {
                "databaseName": "SEMAT",
                "clientName": "Semat"
            },
            {
                "databaseName": "SOMATRANS",
                "clientName": "Somatrans"
            },
            {
                "databaseName": "TACCROBOIS",
                "clientName": "Nom adhérent"
            },
            {
                "databaseName": "TANET",
                "clientName": "ANET MATERIAUX"
            },
            {
                "databaseName": "TAUDE",
                "clientName": "Aude"
            },
            {
                "databaseName": "TBAZARD",
                "clientName": "Bazard"
            },
            {
                "databaseName": "TBEAUME",
                "clientName": "Ets BEAUME SAS"
            },
            {
                "databaseName": "TBELLET",
                "clientName": "Sarl Bellet Frères"
            },
            {
                "databaseName": "TBERLOT",
                "clientName": "BERLOT MATERIAUX"
            },
            {
                "databaseName": "TBERNOUX",
                "clientName": "Bernoux"
            },
            {
                "databaseName": "TBOMAT",
                "clientName": "BO Matériaux"
            },
            {
                "databaseName": "TBOUTEAU",
                "clientName": "BOUTEAU MATERIAUX"
            },
            {
                "databaseName": "TBOUTOL",
                "clientName": "Boutolleau"
            },
            {
                "databaseName": "TBRICOSERV",
                "clientName": "Brico Serv"
            },
            {
                "databaseName": "TBUISSON",
                "clientName": "SCIERIE BUISSON S.A.S"
            },
            {
                "databaseName": "TCAROBAIE",
                "clientName": "CAROBAIE MATERIAUX"
            },
            {
                "databaseName": "TCHAMPI",
                "clientName": "CHAMPIGNELLES"
            },
            {
                "databaseName": "TCHEVREUSE",
                "clientName": "Chevreuse"
            },
            {
                "databaseName": "TCITEBOIS",
                "clientName": "Citebois"
            },
            {
                "databaseName": "TCOURCIER",
                "clientName": "Courcier"
            },
            {
                "databaseName": "TCOVAMAT",
                "clientName": "STEINER"
            },
            {
                "databaseName": "TDEPOMAT",
                "clientName": "Depomat"
            },
            {
                "databaseName": "TDESHAYES",
                "clientName": "DESHAYES"
            },
            {
                "databaseName": "TETCHALUS",
                "clientName": "ETCHALUS"
            },
            {
                "databaseName": "TEUROMAT",
                "clientName": "EUROMAT SERVICES"
            },
            {
                "databaseName": "TEXPERTBAR",
                "clientName": "Atel. Facadier Expertr Bradage"
            },
            {
                "databaseName": "TFIXECO",
                "clientName": "Fixeco"
            },
            {
                "databaseName": "TFOUQUES",
                "clientName": "Fouques"
            },
            {
                "databaseName": "TGINESTET",
                "clientName": "Ginestet"
            },
            {
                "databaseName": "THOME",
                "clientName": "Home"
            },
            {
                "databaseName": "TJACOB",
                "clientName": "Jacob"
            },
            {
                "databaseName": "TJAMARD",
                "clientName": "Jamard"
            },
            {
                "databaseName": "TJBETON",
                "clientName": "J BETON"
            },
            {
                "databaseName": "TLANDREAU",
                "clientName": "LANDREAU"
            },
            {
                "databaseName": "TLAURENT",
                "clientName": "DELSOL"
            },
            {
                "databaseName": "TLEBRETON",
                "clientName": "Lebreton"
            },
            {
                "databaseName": "TLILIAN",
                "clientName": "Lilian"
            },
            {
                "databaseName": "TMCC",
                "clientName": "MCC"
            },
            {
                "databaseName": "TRATEAU",
                "clientName": "RATEAU"
            },
            {
                "databaseName": "TROMERA",
                "clientName": "ETS ROMERA"
            },
            {
                "databaseName": "TSABLEMAT",
                "clientName": "SABLEMAT"
            },
            {
                "databaseName": "TSERAC",
                "clientName": "Serac"
            },
            {
                "databaseName": "TSODIAC",
                "clientName": "SODIAC MATERIAUX"
            },
            {
                "databaseName": "TSVB",
                "clientName": "SVB"
            },
            {
                "databaseName": "TSYBGOMAT",
                "clientName": "SYBGOMAT"
            },
            {
                "databaseName": "TVAGLIO",
                "clientName": "VAGLIO"
            },
            {
                "databaseName": "TVCMC",
                "clientName": "VCMC"
            },
            {
                "databaseName": "TVERHEYDE",
                "clientName": "Verheyde"
            },
            {
                "databaseName": "TVEYRIERE",
                "clientName": "VEYRIERE MATERIAUX"
            },
            {
                "databaseName": "TVILLERS",
                "clientName": "Villers"
            },
            {
                "databaseName": "VIMOND",
                "clientName": "Vimond Mat"
            }
        ],
    },

    "fakearticles": [
        {
            gencod: "3177810013982",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011534",
                    ARAREFF: "SP013004",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN GRILLAGE VERT HT 1M",
                    ARTLI2: "LARGEUR 1M",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 95.71,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 75.56,
                    ARPPXA: 75.56,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10256684",
                    ARAREFF: "SP013004",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN VERT 100 X HT 100",
                    ARTLI2: "10UN/PAL",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 123.84,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 143.92,
                    ARPPXA: 90.67,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810013999",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011535",
                    ARAREFF: "SP013005",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN GRILLAGE VERT 1,25M",
                    ARTLI2: "LARGEUR 1M",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 109.07,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 84.47,
                    ARPPXA: 84.47,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10002114",
                    ARAREFF: "SP013005",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN VERT 100 X HT 125",
                    ARTLI2: "10UN/PAL",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 132.69,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 160.89,
                    ARPPXA: 101.36,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810014002",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011536",
                    ARAREFF: "SP013006",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN GRILLAGE VERT 1,50M",
                    ARTLI2: "LARGEUR 1M",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 123.76,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 94.9,
                    ARPPXA: 94.9,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10009087",
                    ARAREFF: "SP013006",
                },
                pfProductsNames: {
                    ARTLIB: "PORTILLON ECO GARDEN VERT 100 X HT 150",
                    ARTLI2: "10UN/PAL",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 153.91,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 180.77,
                    ARPPXA: 113.89,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810012756",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011540",
                    ARAREFF: "GP450001",
                },
                pfProductsNames: {
                    ARTLIB: "GRILLAGE PANNEAU AXIS S VERT - HT 1,03M",
                    ARTLI2: "LG 2M48 - FIL DE 4 - MAILLE 100X55  2M48",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 19.99,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 15.99,
                    ARPPXA: 15.99,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10009435",
                    ARAREFF: "GP450001",
                },
                pfProductsNames: {
                    ARTLIB: "PANNEAU AXIS S 100X50MM F4MM HT1M03",
                    ARTLI2: "LG.2M48",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 27.61,
                    ARVDEB: "04/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 37.08,
                    ARPPXA: 19.47,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810002498",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011696",
                    ARAREFF: "GT850001",
                },
                pfProductsNames: {
                    ARTLIB: "GRILLAGE TTX GALVA AF 13X0,7 -1M00 10 ML",
                    ARTLI2: " RLX 10 ML",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 26.06,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 21.46,
                    ARPPXA: 21.46,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10012386",
                    ARAREFF: "GT850001",
                },
                pfProductsNames: {
                    ARTLIB: "GRILLAGE TTX GALVA AF-13X0,7 H: 1M -10ML",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "RX",
                },
                pfProductPrice: {
                    ARVHTC: 33.81,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 30.66,
                    ARPPXA: 21.46,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810047321",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011768",
                    ARAREFF: "GA201011",
                },
                pfProductsNames: {
                    ARTLIB: "SACHET DE 10 BLOQUEURS UNIVERSELS",
                    ARTLI2: "PR POTEAUX AXOR/AXOR PLUS ET AXYLE",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 16.88,
                    ARVDEB: "01/09/22",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 13.7,
                    ARPPXA: 13.7,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10009460",
                    ARAREFF: "GA201011",
                },
                pfProductsNames: {
                    ARTLIB: "CALE DE POSE AXIS (SACHET DE 10 PIECES)",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 25.13,
                    ARVDEB: "01/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 26.09,
                    ARPPXA: 18.26,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810021994",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011828",
                    ARAREFF: "OC400020",
                },
                pfProductsNames: {
                    ARTLIB: "100 CLIPS FIX LIXO/LIXO+ 7016",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 6.06,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 4.71,
                    ARPPXA: 4.71,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015909",
                    ARAREFF: "OC400020",
                },
                pfProductsNames: {
                    ARTLIB: "CLIPS DE FIXATION POUR OCCULTATION LIXO",
                    ARTLI2: "RAL: 7016 100/SACHET",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 8.66,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 6.73,
                    ARPPXA: 4.71,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810019854",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011855",
                    ARAREFF: "OC400014",
                },
                pfProductsNames: {
                    ARTLIB: "100 CLIPS FIX LIXO/LIXO+ 6005",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 6.01,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 4.71,
                    ARPPXA: 4.71,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10016171",
                    ARAREFF: "OC400014",
                },
                pfProductsNames: {
                    ARTLIB: "CLIPS DE FIXATION POUR OCCULTATION LIXO",
                    ARTLI2: "RAL: 6005 VERT - 100UN/SACHET",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 8.66,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 6.73,
                    ARPPXA: 4.71,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3177810030637",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99011860",
                    ARAREFF: "OC600110",
                },
                pfProductsNames: {
                    ARTLIB: "LISSE LIXO + FINITION 2.50M 7016",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 5.83,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 4.73,
                    ARPPXA: 4.73,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015758",
                    ARAREFF: "OC600110",
                },
                pfProductsNames: {
                    ARTLIB: "OCCULTATION - LISSE DE FINITION LG 2M50",
                    ARTLI2: "POUR RENFORCER SYSTEME LIXOPRO",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 10.89,
                    ARVDEB: "01/09/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 8.45,
                    ARPPXA: 5.92,
                    ARPDPX: "01/09/23",
                },
            },
        },
        {
            gencod: "3700302410060",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012510",
                    ARAREFF: "TA03STDTA07",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 80G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 11.57,
                    ARVDEB: "01/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 10.4,
                    ARPPXA: 10.4,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015413",
                    ARAREFF: "99012510",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 80G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 22.45,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 14.24,
                    ARPPXA: 14.24,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410084",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012512",
                    ARAREFF: "TA03STDTA09",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 2 X 3 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 3.11,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 2.8,
                    ARPPXA: 2.8,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10019330",
                    ARAREFF: "99012512",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 2 X 3 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 7.37,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 3.81,
                    ARPPXA: 3.81,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410091",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012513",
                    ARAREFF: "TA03STDTA10",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 4 X 5 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 10.24,
                    ARVDEB: "01/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 9.2,
                    ARPPXA: 9.2,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10019331",
                    ARAREFF: "99012513",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 4 X 5 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 22.23,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 12.62,
                    ARPPXA: 12.62,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410107",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012514",
                    ARAREFF: "TA03STDTA11",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 19.59,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 17.6,
                    ARPPXA: 17.6,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10019332",
                    ARAREFF: "99012514",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 36.28,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 24.29,
                    ARPPXA: 24.29,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410114",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012515",
                    ARAREFF: "TA03STDTA12",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 6 X 10 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 29.55,
                    ARVDEB: "01/12/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 26.55,
                    ARPPXA: 26.55,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10019333",
                    ARAREFF: "99012515",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 6 X 10 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 52.81,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 36.62,
                    ARPPXA: 36.62,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410121",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012516",
                    ARAREFF: "TA03STDTA13",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 8 X 12 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 48.87,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 43.91,
                    ARPPXA: 43.91,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10019334",
                    ARAREFF: "99012516",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 8 X 12 M 140G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 67.96,
                    ARVDEB: "30/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 48.87,
                    ARPPXA: 48.87,
                    ARPDPX: "01/10/23",
                },
            },
        },
        {
            gencod: "3700302410145",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012518",
                    ARAREFF: "TA03STDTA15",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 2 X 3 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 5.47,
                    ARVDEB: "15/01/24",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 4.9,
                    ARPPXA: 4.9,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015653",
                    ARAREFF: "99012518",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 2 X 3 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 11.1,
                    ARVDEB: "15/02/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 6.34,
                    ARPPXA: 6.34,
                    ARPDPX: "15/02/23",
                },
            },
        },
        {
            gencod: "3700302410152",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012519",
                    ARAREFF: "TA03STDTA16",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 4 X 5 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 18.03,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 16.2,
                    ARPPXA: 16.2,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015654",
                    ARAREFF: "99012519",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 4 X 5 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 31.57,
                    ARVDEB: "30/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 18.03,
                    ARPPXA: 18.03,
                    ARPDPX: "01/10/23",
                },
            },
        },
        {
            gencod: "3700302410169",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012520",
                    ARAREFF: "TA03STDTA17",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 36.28,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 32.6,
                    ARPPXA: 32.6,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015655",
                    ARAREFF: "99012520",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 5 X 8 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 63.53,
                    ARVDEB: "30/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 36.28,
                    ARPPXA: 36.28,
                    ARPDPX: "01/10/23",
                },
            },
        },
        {
            gencod: "3700302410176",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012521",
                    ARAREFF: "TA03STDTA18",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 6 X 10 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 54.31,
                    ARVDEB: "01/10/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 48.8,
                    ARPPXA: 48.8,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10015656",
                    ARAREFF: "99012521",
                },
                pfProductsNames: {
                    ARTLIB: "BACHES A OEILLETS 6 X 10 M 240G/M2",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 95.1,
                    ARVDEB: "30/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 54.31,
                    ARPPXA: 54.31,
                    ARPDPX: "01/10/23",
                },
            },
        },
        {
            gencod: "3306940047523",
            plateformData: {
                pfProductCodes: {
                    ARTSOC: "PF",
                    ARTCOD: "99012525",
                    ARAREFF: "4P03ECHQM02",
                },
                pfProductsNames: {
                    ARTLIB: "ECO CHANTIER BLANC 60X60X70 CMU 500 KG",
                    ARTLI2: "",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 2.45,
                    ARVDEB: "01/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 2.0,
                    ARPPXA: 2.0,
                    ARPDPX: "",
                },
            },
            clientData: {
                pfProductCodes: {
                    ARTSOC: "VI",
                    ARTCOD: "10016291",
                    ARAREFF: "99012525",
                },
                pfProductsNames: {
                    ARTLIB: "BIG BAG PETIT MODELE BLANC 1/4 M3 CHARGE",
                    ARTLI2: "MAXI 500 KG",
                },
                pfProductsUnities: {
                    CVSUA: "UN",
                    CVSUV: "UN",
                },
                pfProductPrice: {
                    ARVHTC: 4.16,
                    ARVDEB: "30/11/23",
                },
                pfProductBuyingPrice: {
                    ARPPXB: 2.58,
                    ARPPXA: 2.45,
                    ARPDPX: "01/11/23",
                },
            },
        },
    ]
}
