import { useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Box,
  Tooltip,
  styled,
  ButtonProps,
  TextField,
} from "@mui/material";
import axios from "axios";
import ExcelDataGrid from "../tools/ExcelDataGridComponent/ExcelDataGrid";
import { useGridApiRef } from "@mui/x-data-grid";
import MiniDrawer from "../tools/MiniDrawer";
import { purple } from "@mui/material/colors";
import s from "../../testFile.json";
import { FaEdit, FaSearch } from "react-icons/fa";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { enqueueSnackbar } from "notistack";
interface InvalidCell {
  key: string;
  status: string;
}

const EditProduct = () => {
  // State constants ///////////////////////////////////////////////////////////////////////////
  const [jsonData, setJsonData] = useState<any[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [retriveStatus, setRetriveStatus] = useState<string>("");
  const [isValidInputSoc, setIsValidInputSoc] = useState<boolean>(true);
  const [isValidInputCod, setIsValidInputCod] = useState<boolean>(true);
  const [keyMapping, setKeyMapping] = useState<any[]>([]);

  const [uploadError, setUploadError] = useState<string>("");
  const [invalidCells, setInvalidCells] = useState<InvalidCell[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [productCode, setProductCode] = useState<string>("");
  const [SocityCode, setSocityCode] = useState<string>(""); // assuming this is a string, adjust type if necessary
  const apiRef = useGridApiRef();
  const testFile: any = s;
  const [selectedColindex, setSelectedColindex] = useState<number>(0);
  const [widthDrawer, setWidthDrawer] = useState("");
  const { instance, accounts, inProgress } = useMsal();
  const msalInstance = instance;
  // Functions for data manipulation / update ////////////////////////////////////////////////////
  const indexRef = useRef({ index1: 0, index2: 0 });
  const tokenRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: true, // Force a token refresh
  };
  useEffect(() => {
    indexRef.current.index1 = selectedRow;
    indexRef.current.index2 = selectedColindex;
  }, [selectedRow, selectedColindex]);

  const getToken = async (account: AccountInfo) => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      console.log(response);
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If interaction is required, fallback to an interactive method
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        console.log(response);

        return response.idToken;
      } else {
        console.log("error token ");
        throw error;
      }
    }
  };
  const handleInvalidCellsChange = (invalidCells: {
    [key: string]: string;
  }) => {
    const invalidCellsArray: InvalidCell[] = Object.entries(invalidCells).map(
      ([key, status]) => ({ key, status })
    );
    setInvalidCells(invalidCellsArray);
  };
  const isDisabled =
    !isValidInputCod ||
    !isValidInputSoc ||
    productCode.length === 0 ||
    SocityCode.length === 0;
  const isDisabled2 = invalidCells.length > 0;

  const buttonStyle = {
    color: isDisabled ? "gray" : "black",
    background: isDisabled ? "#FFEA7F" : "#FFD100", // Lighter shade when disabled
    cursor: isDisabled ? "not-allowed" : "pointer",
  };
  const buttonStyle2 = {
    color: isDisabled2 ? "gray" : "black",
    background: isDisabled2 ? "#FFEA7F" : "#FFD100", // Lighter shade when disabled
    cursor: isDisabled2 ? "not-allowed" : "pointer",
  };
  const handleJsonExport = (data: any[]) => {
    const jsonDataWithIds = data.map((obj, index) => ({
      ...obj,
      id: index + 1,
    }));
    setJsonData(jsonDataWithIds);
    setKeyMapping(jsonDataWithIds);
  };

  const handleRowUpdate = (updatedRow: any) => {
    setJsonData((prevData) =>
      prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  };

  const handleCellClick = (key: string) => {
    // Extract row and column from the key
    const [row, column] = key.split("-");
    const columnKeys = Object.keys(keyMapping[0]);
    const colIndex = columnKeys.indexOf(column);

    // Set selected row and column for your component state
    setSelectedRow(parseInt(row));
    setSelectedColumn(column);
    setSelectedColindex(colIndex);

    // Using the API to scroll to the cell
    apiRef.current.scrollToIndexes({
      rowIndex: parseInt(row),
      colIndex: colIndex,
    });

    // Use a timeout to ensure the grid has rendered the cell after scrolling
    setTimeout(() => {
      // Now that the cell should be rendered, try to get the cell element
      const cellElement = apiRef.current.getCellElement(parseInt(row), column);
      if (cellElement) {
        // Optionally, scroll into view if necessary
        cellElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });

        // Start editing the cell
        apiRef.current.startCellEditMode({ id: row, field: column });
      } else {
        console.error(
          `Cell at row ${row} and column ${column} is not found after scroll.`
        );
      }
    }, 300); // Adjust delay as needed based on grid rendering speed
  };
  const handleRetriveProduct = async () => {
    console.log("test", testFile.articles);
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common[
      "Authorization"
      //  ] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJlNTk0Zjg1ZC04YzljLTQ3YzEtOGM3ZC0xMGYxMGNlNWYwZTIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vN2VlZTYyYTEtMDA1Mi00YWU2LTlkNzUtMzJhYTVlOWM1NzMzL3YyLjAiLCJpYXQiOjE3MTk0ODk2MjUsIm5iZiI6MTcxOTQ4OTYyNSwiZXhwIjoxNzE5NDkzNTI1LCJuYW1lIjoiSnVsaWVuIEzDqWdlciIsIm5vbmNlIjoiMDE5MDU5OTUtZjhhMi03NGU4LWFkNTctZjc3M2I0Y2MyMDYyIiwib2lkIjoiMzc5NTNlZDEtYTI3Ny00NDRkLTkwMTItY2NkZjFiZDAzZmFjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiamxlZ2VyQHRvdXRmYWlyZS5mciIsInJoIjoiMC5BVWNBb1dMdWZsSUE1a3FkZFRLcVhweFhNMTM0bE9XY2pNRkhqSDBROFF6bDhPSU5BUzQuIiwic3ViIjoiUFluZzdVVWhMeDZ1VW9nOGdLLWdGOGptYUdpQnVXeXJfdlNhcm1pWXZHdyIsInRpZCI6IjdlZWU2MmExLTAwNTItNGFlNi05ZDc1LTMyYWE1ZTljNTczMyIsInV0aSI6IjZvRFBaZzNlMzB1T3RoMkNxLXU3QUEiLCJ2ZXIiOiIyLjAifQ.dzQEgJj4KrUxlcGDuZ5maE7nOaNw-0FxlU1Gn6qnHZju7xI2xD8tp7OFyNqJkNzNJ-TS6gE4qKaQzMIdbFo5oG0b8svC8ks7atM7QwpKUgqGjHZ70opLXVzJvByeSgWvufPSmzyHq19BjUrIKO09MDKXq5y7-YK-IXeQZMix2WLhdUv7B2ZKVEvifubYPFj0m9M4xzqHdAWeF9aacDfjE-zACGy0n8cSUZbIbrE3tM_y5ALlBO71KjwnvNxRXXkvSdwx1P1CqI63wd98hgGjv6W4GB5764XQcG3aVMpnshzxgzH694ElQSnlMYPv5YKQXENQZw3jn3ItLG0HECKIjw
      //`;
    ] = `Bearer ${token ?? ""}`;
    const response: any = await axios
      .get(
        `https://api.staging.gcp.toutfaire.com/v1/gw/v1/apitools/tools/getArticle/${SocityCode}/${productCode}`,
        {
          headers: {
            // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCJ9.eyJhdWQiOiJlNTk0Zjg1ZC04YzljLTQ3YzEtOGM3ZC0xMGYxMGNlNWYwZTIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vN2VlZTYyYTEtMDA1Mi00YWU2LTlkNzUtMzJhYTVlOWM1NzMzL3YyLjAiLCJpYXQiOjE3MjMwMzc5OTYsIm5iZiI6MTcyMzAzNzk5NiwiZXhwIjoxNzIzMDQxODk2LCJuYW1lIjoiSnVsaWVuIEzDiUdFUiIsIm5vbmNlIjoiMDE5MTJkMTUtOWRmNS03NDJlLWE0OTYtZDM1OTNlOWVlNjM1Iiwib2lkIjoiMzc5NTNlZDEtYTI3Ny00NDRkLTkwMTItY2NkZjFiZDAzZmFjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiamxlZ2VyQHRvdXRmYWlyZS5mciIsInJoIjoiMC5BVWNBb1dMdWZsSUE1a3FkZFRLcVhweFhNMTM0bE9XY2pNRkhqSDBROFF6bDhPSU5BUzQuIiwic3ViIjoiUFluZzdVVWhMeDZ1VW9nOGdLLWdGOGptYUdpQnVXeXJfdlNhcm1pWXZHdyIsInRpZCI6IjdlZWU2MmExLTAwNTItNGFlNi05ZDc1LTMyYWE1ZTljNTczMyIsInV0aSI6Ik1HWmMyVlBfVFVtdXBIWi1lMmtnQUEiLCJ2ZXIiOiIyLjAifQ.Qo6wDnCugZLzcxpCWhzgbrIVFmXQIMM2Ng8m7fJTRTGvjLfGV3q1fJRB-VD9lk6qQ5r_zYNwYkThOlZOTbPk_NZUXtW2gtiHcAS8f2NRGr0NiTX38VMrCTaZBjd6B2B1gziI-YeiBbvQaZkUFr_x6lBxrEGy6Dt7QgSIqGOEqXoYK2fToIMdreZtaKWG9UiA-Wtmr6gbMr8spm83ZKbw63PUoQUspPbdTKt5_GxGRg_l2EpMUxVSPPZPHE7322kfk2Yu0jJsS6ksJaptm96nJTijG54j7KLtNNvBSkARNQU_GheSOeN8xEDalYjB3Jztvug7prUSIn78vXJagguMDA`,
          },
        }
      )
      .finally(() => {})
      .catch(() => {
        setRetriveStatus("");
      });
    if (!response) {
      handleSnackbar("Erreur Réseau", "error");

      return;
    }
    console.log(response);
    if (response.data.out_code === "404") {
      handleSnackbar("Article Inconnu", "error");

      return;
    }
    if (response.data.out_code != "000")
      handleSnackbar("Article Récupéré", "success");
    setJsonData([response.data.out_data]);
    //const { articles } = testFile;
    console.log(response);
    let array = [response.data.out_data];

    array.map((article: any, index: any) => {
      console.log("buyingPrice", article);
      if (article.BuyingPrices) {
        console.log("buyingPrice");
        const buyingPrices = article.BuyingPrices;
        delete article.BuyingPrices; // Remove the BuyingPrices field
        Object.assign(article, buyingPrices); // Merge the properties from buyingPrices into articles
      }

      if (article.SellingPrices) {
        const sellingPrices = article.SellingPrices;
        delete article.SellingPrices; // Remove the SellingPrices field
        Object.assign(article, sellingPrices); // Merge the properties from sellingPrices into articles
      }
      article.id = index + 1;
      console.log("index", index);
      console.log(SocityCode, article);
      const reverseKeyMapping: any = {
        ArticleSoc: "Code société",
        ArticleCode: "Code Article",
        ArticleName: "Libellé 1",
        ArticleName2: "Libellé 2",
        Group: "Grp de famille",
        Family: "Famille",
        BuyingUnity: "UA",
        StockUnity: "US",
        SalesUnity: "UV",
        UnityBuyingOnSales: "US/UA",
        UnityStockOnSales: "US/UV",
        Provider: "FRN",
        ReferenceProvider: "Ref FRN",
        Gencod: "Gencod",
        Gencod2: "Gencod 2",
        Gencod3: "Gencod 3",
        GrossBuyingPrice: "prix achat brut",
        Reduction1: "Remise 1",
        PercentOnValueReduction1: "Valeur pourcent (V/P)",
        Reduction2: "Remise 2",
        PercentOnValueReduction2: "Valeur pourcent",
        Reduction3: "Remise 3",
        PercentOnValueReduction3: "Valeur pourcent _v",
        NetBuyingPrice: "Prix d'achat net",
        BasePrice: "Prix de base",
        SalesPriceWithoutTaxesA: "Prix vente barème A",
        SalesPriceWithoutTaxesB: "B",
        SalesPriceWithoutTaxesC: "C",
        SalesPriceWithoutTaxesD: "D",
        SalesPriceWithoutTaxesE: "E",
        SalesPriceWithoutTaxesF: "F",
        SalesPriceWithoutTaxesG: "G",
        SalesPriceWithoutTaxesH: "H",
        CoefficientA: "Coef A",
        CoefficientB: "Coef B",
        CoefficientC: "Coef C",
        CoefficientD: "Coef D",
        CoefficientE: "Coef E",
        CoefficientF: "Coef F",
        CoefficientG: "Coef G",
        CoefficientH: "Coef H",
        ModifiedBy: "Qui",
        ModifiedOn: "Quand",
        RoundedBuyingPrice: "Arrondie PXA",
        RoundedGrossPrice: "Arrondie PXB",
        CostPrice: "Prix de revient",
        RoundedCostPrice: "Arrondie Prix de revient",
        TRSOnUnityAmmount: "cou tp",
        Lot: "ls cmem",
        VATCode: "tva",
        OnInternet: "ecommerce",
        PacketForSale: "paquet a la vente",
        BuyingCentralCode: "Code Centrale achat",
        LSArticle: "LS GXO",
        ExpiryPeriod: "DLUO",
        HazardousMaterial: "Dangereux",
        FreezingProduct: "Craint le gele",
        PackageLength: "Longueur ",
        PackageWidth: "Largeur",
        PackageHeight: "Hauteur",
        NbPackagePerLayer: "NB COLIS PAR COUCHE",
        NbLayerPerPalette: "COUCHE PAR PAL",
        LayerHeight: "Hauteur Couche",
        Bath: "Bain",
        Caliber: "Calibre",
        Supplier: "Approvisioinneur",
        AnnualRequestFixed: "Vente annuelle",
        SupplierDelay: "Delai FRN",
        PrevisionMethod: "méthode delai",
        PaletteLength: "Longeur palette",
        PaletteWidth: "largeur palette",
        PaletteHeight: "Hauteur palette",
        PaletteWeight: "Poids palette",
        UnityCode1: "CVSU1",
        UnityCoeff1: "CVSRFU1",
        CVSU1ToCVSRFU1: "CVSU1UR",
        UnityCode2: "CVSU2",
        UnityCoeff2: "CVSRFU2",
        CVSU2ToCVSRFU2: "CVSU2UR",
        ConditioningPalette: "Conditionnement",
        NumberOfItemsPerPackage: "PCB GXO",
        BuyingMultiple: "Multiple d'achat",
        Conditioning: "ARANBP",
        DelayBeforDLUO: "delai avant benne DLUO",
        Packaged: "Colisée",
        StockCm: "Stock CM",
        MinimalPrice: "Prix plancher",
        WeightByUnity: "Poids/U",
      };
      const reverseMappingAndConvert = (article: any) => {
        let reversedArticle: any = {};

        Object.keys(article).forEach((key) => {
          if (reverseKeyMapping[key]) {
            reversedArticle[reverseKeyMapping[key]] = article[key];
          } else {
            reversedArticle[key] = article[key]; // Retain key if not in mapping
          }
        });

        // Assuming you need to delete these keys
        delete reversedArticle["Libéllé exact"];
        delete reversedArticle["ARTDAF"];

        return reversedArticle;
      };
      if (SocityCode === article.ArticleSoc) {
        const reversedArticle = reverseMappingAndConvert(article);

        setJsonData([reversedArticle]);
        console.log("article", reversedArticle);

        setKeyMapping([reversedArticle]);
      }
    });

    //setJsonData(articles);
    console.log("fff", testFile.articles);
    const dataWithoutId = jsonData.map((obj) => {
      // Clone each object to avoid mutating the original data
      const newObj = { ...obj };

      // Remove the id property from each object
      delete newObj.id;
    });

    // axios
    //   .put(
    //     "https://api.staging.gcp.toutfaire.com/v1/gw/v1/apitools/tools/editProduct",
    //     {}
    //   )
    //   .then((product: any) => {
    //     setRetriveStatus("Article modifié.");
    //     setJsonData(product);
    //   })
    //   .catch(() => {
    //     setRetriveStatus("");
    //   });
  };

  const handleUploadJSON = () => {
    const dataWithoutId = jsonData.map((obj) => {
      // Clone each object to avoid mutating the original data
      const newObj = { ...obj };
      newObj.BuyingPrices = obj.BuyingPrices[0];
      newObj.SellingPrices = obj.SellingPrices[0];
      // Remove the id property from each object
      delete newObj.id;

      return newObj;
    });
    // Call API to post the JSON data
    axios
      .post("URL_DU_ENDPOINT_SUR_LE_SERVEUR_DISTANT", dataWithoutId, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handleSnackbar("Article Modifié", "success");
      })
      .catch(() => {
        handleSnackbar("Erreur sur l'envoi des données.", "error");
      });
  };

  const handleSnackbar = (message: any, variant: any) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      preventDuplicate: true,
    });
  };

  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box
        style={{
          flexGrow: 1,
          padding: "85px 0px 0px 32px",
          width: widthDrawer,
        }}
      >
        <div style={{ flexGrow: 1, alignItems: "center" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <Typography color="text.primary">
              Modification d'article{" "}
            </Typography>
          </Breadcrumbs>
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Modification d'article
          </Typography>{" "}
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <TextField
              style={{ width: "150px" }}
              id="SocityCode"
              label="Code Société"
              variant="outlined"
              size="small"
              value={SocityCode}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 2) {
                  const isValid = /^[A-Z]{2}$/.test(inputValue);
                  setIsValidInputSoc(isValid);
                  setSocityCode(inputValue);
                }
              }}
              error={!isValidInputSoc && SocityCode.length !== 0}
              helperText={
                !isValidInputSoc && SocityCode.length !== 0
                  ? " Le Code Société doit contenir exactement 2 caractères en majuscules "
                  : ""
              }
            />
            <TextField
              style={{ width: "250px" }}
              id="productCode"
              label="Code Article"
              variant="outlined"
              size="small"
              value={productCode}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 8) {
                  setProductCode(inputValue);
                  setIsValidInputCod(false);
                  if (inputValue.length === 8) setIsValidInputCod(true);
                }
              }}
              error={productCode.length !== 8 && productCode.length !== 0}
              helperText={
                productCode.length !== 8 && productCode.length !== 0
                  ? "Le Code Article doit contenir 8 caractères"
                  : ""
              }
            />
            <span>
              <Button
                style={buttonStyle}
                sx={{}}
                variant="contained"
                onClick={handleRetriveProduct}
                disabled={
                  !isValidInputCod ||
                  !isValidInputSoc ||
                  productCode.length === 0 ||
                  SocityCode.length === 0
                }
              >
                Rechercher&nbsp;
                <FaSearch />
              </Button>
              <Grid item>
                {retriveStatus && <Typography>{retriveStatus}</Typography>}
              </Grid>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {jsonData.length > 0 && (
              <div>
                <Grid container spacing={2}>
                  <Grid item>
                    <Tooltip
                      title={
                        invalidCells.length > 0 ? "Vérifier votre fichier" : ""
                      }
                    >
                      <span>
                        <Button
                          sx={{ marginTop: "20px" }}
                          variant="contained"
                          onClick={handleUploadJSON}
                          style={buttonStyle2}
                          disabled={invalidCells.length > 0}
                        >
                          Enregistrer les modifications &nbsp;
                          <FaEdit />
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {uploadStatus && <Typography>{uploadStatus}</Typography>}
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          {invalidCells.length > 0 && (
            <Box
              mt={2}
              mb={2}
              p={2}
              bgcolor="#EF5350"
              color="white"
              borderRadius={1}
            >
              <Typography variant="h6">Liste d'erreurs:</Typography>
              {Array.from(new Set(invalidCells.map((cell) => cell.status))).map(
                (status) => (
                  <div key={status}>
                    <Typography variant="subtitle1" gutterBottom>
                      -{status}
                    </Typography>
                    <ul>
                      {invalidCells
                        .filter((cell) => cell.status === status)
                        .map((cell, index) => (
                          <li
                            key={index}
                            onClick={() => handleCellClick(cell.key)}
                            style={{ cursor: "pointer" }}
                          >
                            Cellule n°{cell.key}
                          </li>
                        ))}
                    </ul>
                  </div>
                )
              )}
            </Box>
          )}
        </div>
        <div style={{ display: "flex", paddingBottom: "20px" }}>
          <ExcelDataGrid
            data={jsonData}
            onRowUpdate={handleRowUpdate}
            onInvalidCellsChange={handleInvalidCellsChange}
            selectedColumn={selectedColumn}
            selectedRow={selectedRow}
            apiRef={apiRef}
            index1={"1"}
            index2={"1"}
            title="Modification d'article"
          />
        </div>

        {uploadError && <Typography color="error">{uploadError}</Typography>}
      </Box>
    </MiniDrawer>
  );
};

export default EditProduct;
